<template>
  <section>
    <section class="partner-tenant-about-us-top-section">
      <div class="partner-tenant-about-us-top-content-container">
        <div class="partner-tenant-about-us-top-titles-container">
          <h3 class="partner-tenant-about-us-top-title-1">About BeGlobalii</h3>
          <h3 class="partner-tenant-about-us-top-title-2">
            We create access to global academic and career opportunities for students and recent graduates.
          </h3>
        </div>
        <div>
          <p class="partner-tenant-about-us-top-description">
            We ensure all students and recent grads, particularly those from underrepresented 
            backgrounds, are equipped with the knowledge and confidence to pursue global 
            academic and career experiences. Our vision is that all young people have 
            access to global opportunities that position them to thrive in diverse communities 
            and workplaces.
          </p>
          <div class="partner-tenant-about-us-our-story-small-card stay-connected">
            <div class="partner-tenant-about-us-our-story-small-card-title">Stay Connected!</div>
            <div class="partner-tenant-about-us-our-story-small-card-logos-container">
              <a
                class="partner-tenant-about-us-our-story-logo-button"
                href="https://www.instagram.com/beglobalii/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fa-brands fa-instagram"></i>
              </a>
              <a
                class="partner-tenant-about-us-our-story-logo-button"
                href="https://www.linkedin.com/company/diversity-abroad"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fa-brands fa-linkedin"></i>
              </a>
              <!-- <a class="partner-tenant-about-us-our-story-logo-button" href="#" target="_blank" rel="noopener noreferrer">
                <i class="fa-brands fa-twitter"></i>
              </a> -->
              <a class="partner-tenant-about-us-our-story-logo-button" href="mailto:community@beglobalii.com">
                <i class="fa-solid fa-envelope"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="partner-tenant-about-us-our-story-section">
      <img
        class="partner-tenant-about-us-our-story-image"
        src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/our-story-bg.webp"
        alt="Our story image"
      />
      <div class="partner-tenant-about-us-our-story-content-container">
        <div class="partner-tenant-about-us-our-story-text-card">
          <h3 class="partner-tenant-about-us-our-story-title">Our Story</h3>
          <p class="partner-tenant-about-us-our-story-description">
            BeGlobalii launched in 2024 with the goal of ensuring that the 
            next generation of students and young people have access to global 
            educational and career opportunities that will position them to 
            thrive in our interconnected world and globally diverse workforce.
            <br /><br />
            Our story started in 2006 when our founder, Andrew Gordon, recognized 
            there were few places for students, particularly those from underrepresented 
            backgrounds, to see themselves represented in study abroad programs. Our 
            answer to this challenge was the website DiversityAbroad.com, which became 
            the go-to source for information for students and young people seeking 
            resources and materials on international programs like study abroad, 
            international scholarships, global internships and teaching opportunities. 
            <br /><br />
            After nearly 20 years online, BeGlobalii continues our efforts to bring 
            together the tools and resources to increase awareness and access to 
            global academic and career opportunities. BeGlobalii plays a role in 
            closing the educational and career opportunity gap through fostering 
            access to the benefits of global academic and career experiences.
          </p>
        </div>
      </div>
    </section>
    <section class="partner-tenant-about-us-what-we-do-section">
      <div class="partner-tenant-about-us-what-we-do-content-container">
        <h3 class="partner-tenant-about-us-what-we-do-title">What We Do</h3>
        <div class="partner-tenant-about-us-what-we-do-tabs-container">
          <div v-for="(item, index) in whatWeDoList" :key="index" class="partner-tenant-about-us-what-we-do-tab">
            <div class="partner-tenant-about-us-what-we-do-tab-title-container">
              <img :src="item.iconUrl" alt="" class="partner-tenant-about-us-what-we-do-tab-icon" />
              <h4 class="partner-tenant-about-us-what-we-do-tab-title">
                {{ item.title }}
              </h4>
              <button
                v-if="expandedDetailsTab === index"
                @click="closeDetailsTab()"
                :aria-label="`Collapse ${item.title} tab`"
                class="partner-tenant-about-us-what-we-do-tab-button"
              >
                <i class="fa-solid fa-minus"></i>
              </button>
              <button
                v-else
                @click="openDetailsTab(index)"
                :aria-label="`Expand ${item.title} tab`"
                class="partner-tenant-about-us-what-we-do-tab-button"
              >
                <i class="fa-solid fa-plus"></i>
              </button>
            </div>
            <p :id="`tab-${index}`" class="partner-tenant-about-us-what-we-do-tab-description">
              {{ item.description }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="partner-tenant-about-us-our-team-section">
      <div class="partner-tenant-about-us-our-team-content-container">
        <div class="partner-tenant-about-us-our-team-text-container">
          <h3 class="partner-tenant-about-us-our-team-title">Our Team</h3>
          <p class="partner-tenant-about-us-our-team-description">
            BeGlobalii’s work is driven by a team of dedicated 
            professionals who are committed to ensuring all 
            students have access to meaningful global educational 
            and career opportunities that position them to thrive 
            in our interconnected world.
          </p>
        </div>
        <div class="partner-tenant-about-us-our-team-cards-container">
          <div v-for="(member, index) in teamMembers" :key="index" class="partner-tenant-about-us-our-team-card">
            <img class="partner-tenant-about-us-our-team-card-image" :src="member.imageUrl" :alt="member.name" />
            <div class="partner-tenant-about-us-our-team-card-text-container">
              <div class="partner-tenant-about-us-our-team-card-name">
                {{ member.name }}
              </div>
              <div class="partner-tenant-about-us-our-team-card-title">
                {{ member.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <partner-tenant-request-demo-section></partner-tenant-request-demo-section>
  </section>
</template>

<script>
import PartnerTenantRequestDemoSection from './components/PartnerTenantRequestDemoSection.vue';

export default {
  name: 'partner-tenant-about-us',
  components: {
    PartnerTenantRequestDemoSection,
  },
  data() {
    return {
      teamMembers: [
        {
          imageUrl:
            'https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/about_us/profile_images/Andrew_Gordon.webp',
          name: 'Andrew Gordon',
          title: 'CEO & Founder',
        },
        {
          imageUrl:
            'https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/about_us/profile_images/Claudio_Castaneda.webp',
          name: 'Claudio Castaneda',
          title: 'Partner Success Manager',
        },
        {
          imageUrl:
            'https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/about_us/profile_images/Eden_Taylor.webp',
          name: 'Eden Taylor',
          title: 'Senior Coordinator for Marketing and Communications',
        },
        {
          imageUrl:
            '	https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/about_us/profile_images/Tolu_Abayomi.webp',
          name: 'Tolu Abayomi',
          title: 'Engineering and Product Lead',
        },
      ],
      whatWeDoList: [
        {
          title: 'We Are a Community',
          description:
            'BeGlobalii is a community that \
            connects current students, recent \
            graduates, parents, and professionals \
            together to reach a common goal; ensure \
            access for all to global academic and career \
            opportunities.',
          iconUrl: 'https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/what-we-do-community.webp',
        },
        {
          title: 'We Connect',
          description:
            'BeGlobalii brings globally focused resources \
            and opportunities into one platform. We are \
            passionate about connecting our students, \
            graduates, and young professionals with the \
            valuable resources and information that are \
            personalized to their needs, all through our \
            easy to navigate platform.',
          iconUrl: 'https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/what-we-do-connect.webp',
        },
        {
          title: 'We Prepare',
          description:
            'Our constituents value our efforts to bring \
            globally centered opportunities and resources \
            into one easily accessible location. Accessing \
            the resources and opportunities that meet your \
            unique academic and career goals isn’t always easy. \
            This is why BeGlobalii is designed to personalize \
            access to the resources, learning experiences, and \
            career opportunities that are a fit for you',
          iconUrl: 'https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/what-we-do-prepare.webp',
        },
      ],
      expandedDetailsTab: -1,
    };
  },
  mounted() {
    this.openDetailsTab(0);
  },
  methods: {
    animateDetailsTab(index, expanding = true) {
      const element = document.getElementById(`tab-${index}`);

      if (expanding) {
        const contentHeight = element.scrollHeight;
        element.style.maxHeight = contentHeight + 'px';
        element.style.marginBottom = 22 + 'px';
      } else {
        element.style.maxHeight = 0;
        element.style.marginBottom = 0;
      }
    },
    openDetailsTab(index) {
      if (this.expandedDetailsTab !== -1) {
        this.animateDetailsTab(this.expandedDetailsTab, false);
      }
      this.expandedDetailsTab = index;
      this.animateDetailsTab(index, true);
    },
    closeDetailsTab() {
      this.animateDetailsTab(this.expandedDetailsTab, false);
      this.expandedDetailsTab = -1;
    },
  },
};
</script>
